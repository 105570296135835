<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import ClientsTableSample from "@/components/ClientsTableSample"
	import CardComponent from "@/components/CardComponent"
	import RemoveModal from "@/components/RemoveModal"
	import { BlacklistService as Service } from "@/services"

	export default {
		name: "BlacklistList",
		components: {
			CardComponent,
			ClientsTableSample,
		},
		data() {
			return {
				table: {
					filter: [
						{
							name: "Tür",
							type: "select",
							field: "type",
							data: [
								{ id: "username", name: "Kullanıcı Adı" },
								{ id: "userId", name: "Kullanıcı ID" },
								{ id: "name", name: "Ad Soyad" },
								{ id: "iban", name: "IBAN" },
								{ id: "ccno", name: "Kredi Kartı No" },
							],
						},
						{
							name: "Değer",
							type: "input",
							field: "value",
						}
					],
					page: {
						title: "Karaliste",
						icon: "plus",
						actions: [
							{
								title: "Karaliste Kaydı Oluştur",
								type: "success",
								icon: "plus",
								action: () => {
									this.$router.push({
										name: "blacklistCreateOrUpdate",
									})
								},
								perm: "Blacklist.Create",
							},
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData()
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "list",
						columns: [
							{
								field: "type",
								label: "Değer Türü",
								sortable: true,
								viewMethod: (val) =>
									this.enums.blacklistTypes[val],
							},
							{
								field: "value",
								label: "Değer",
								sortable: true,
							},
							{
								field: "createdAt",
								label: "Kayıt T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDate
									),
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								sortable: false,
								width: "100px",
								actions: [
									{
										title: "Düzenle",
										type: "success",
										column: "row",
										action: (data) =>
											this.$router.push({
												name: "blacklistCreateOrUpdate",
												params: { id: data.uuid },
											}),
										perm: "Blacklist.Edit",
									},
									{
										column: "row",
										title: "Sil",
										type: "danger",
										perm: "Blacklist.Delete",
										action: (data) => {
											this.openModal({
												component: RemoveModal,
												props: {
													id: data.uuid,
													name: data.name,
													service: data.service,
													type: "Karaliste",
												},
												close: this.$refs.table
													.loadAsyncData,
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
	}
</script>
